<template lang="pug">
    .faq#top
        v-container(fluid).bg-muted.pa-12
            v-expand-transition
                h1.text-center FAQ (Frequently Asked Questions)
        v-container
          v-spacer.my-3

          v-card.pa-1.pa-md-5(flat)
            FaqItem
              template(v-slot:header) Which real estate agencies & property agents are included in the database?
              template(v-slot:body) 
                p All of the listings you see come from {{sources.length}} agency websites.
                p These are:
                ul
                  li(v-for="source in sources", :key="source.id")
                    v-btn(text :href="source.sourceUrl" target="_blank" rel="noopener") {{source.sourceName}}

            FaqItem
              template(v-slot:header) Are you a property agent or real estate agency yourself?
              template(v-slot:body)
                p No, I'm a professional web application developer, not a property agent.
                p If you have a web idea in mind, you can <router-link to="hire-dima">get in touch to discuss it</router-link>.
            
            FaqItem
              template(v-slot:header) How often are your listings updated?
              template(v-slot:body)
                p Listings are automatically "refreshed" multiple times per day.
                p Although if no new real estate has come on the market in Bansko during that time, or if property agents haven't updated their websites yet, of course there are no new listings to show you.
            
                  
            FaqItem
              template(v-slot:header) I love what I'm seeing. Are you for hire to make me a real estate website?
              template(v-slot:body)
                p I'm a professional Web Application developer and yes, <router-link to="hire-dima">I'm available for hire</router-link>.
                p If you have an idea for a real estate website or an 'MVP' (Minimum Viable Product) web application, you can get in touch.
            
            FaqItem
              template(v-slot:header) Can you add my agency or website? Do I have to do anything technical?
              template(v-slot:body)
                p Yes, I can probably add your agency or website's listing to this database.
                p No, you likely do not have to make any technical changes or technical actions at all.
                p If you would like me to add your listings, you can <a href="mailto:dima@mvpforsaas.com">email Dima</a> or use the <a href="#contact">contact form</a> below.
                  
            FaqItem
              template(v-slot:header) Do you need permission from property agents to include these listings?
              template(v-slot:body)
                p Each listing on this website is a link to an already public listing on a real estate agency's public website.
                p Even the listing photos are simply links to the public photo on the real estate agent's public website.
                p The entire internet is made of links from one website to another.
                p It is a positive situation when you find a property listing on this website and get redirected to the original agency's website to see the details and get in contact with them.
                p They'll appreciate your business.
            FaqItem
              template(v-slot:header) Why do you only have listings for Bansko, Bulgaria? Any plans to include other places?
              template(v-slot:body)
                p I created this website in the summer of 2020, while I was staying in Bansko, Bulgaria and looking around at real estate.
                p I can add other towns, cities, and countries. At the moment there is no financial incentive to do that work, but if it's important to you, you can <a href="#contact">get in touch</a>.

            
            FaqItem
              template(v-slot:header) Who created this website?
              template(v-slot:body)
                p This website was created by me, Dima, a Canadian Web Application developer.
                p 
                  router-link(to="hire-dima") I'm available for hire.
            
            FaqItem
              template(v-slot:header) What is your privacy policy?
              template(v-slot:body)
                  router-link(to="privacy") Privacy Policy here
                            
        ContactForm.mt-12
</template>

<script>
const ContactForm = () => import("@/components/ContactForm.vue");
const FaqItem = () => import("@/components/FaqItem.vue");
import { mapGetters } from "vuex";
export default {
  name: "Faq",
  components: { FaqItem, ContactForm },
  computed: {
    ...mapGetters(["sources"])
  },
  mounted() {
    location.hash = "#top";
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/colors.scss";

.bg-muted {
  background: $muted;
}
</style>
