<template lang="pug">
    .hire-dima#top.large
        v-container(fluid).bg-muted.pa-12
            v-expand-transition
                h1.text-center Get your Web App made by a Canadian Web Application Developer
        v-container
            v-spacer.my-12
            v-card.pa-5(flat)
                h2 Hi, I'm Dima. For over a decade I've been making professional web applications.
                p These days I build MVPs, which are rapidly-built <em>"Minimum Viable Product"</em> websites help you launch quickly and test your market.
                p 
                    strong.mr-1 This site you're on was built in under a week.
                    | It is automated, which means it requires little effort on a weekly basis to keep it going.
                p The benefit of working with a Canadian professional is that you avoid miscommunication and cultural misunderstandings. Plus the job gets done to a very high level of quality.
                p.font-weight-bold If you would like to discuss your own web application project, you can <a href="#contact">contact me</a> or visit <a href="https://mvpforsaas.com?ref=bgproperty.today" target="_blank">find out more</a>.

        ContactForm.mt-12
</template>

<script>
const ContactForm = () => import("@/components/ContactForm");
export default {
  name: "HireDima",
  components: { ContactForm },
  mounted() {
    location.hash = "#top";
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";

.bg-muted {
  background: $muted;
}
</style>
